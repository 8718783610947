
<template>
  <div class="grid md:flex">
    <div :class="'w-full sm:w-full ' + `${ sendButton ? 'md:w-10/12' : '' }`">
      <vue-tags-input
      id="idtags"
      v-model="tag"
      :tags="tags"
      :autocomplete-items="autocompleteItems"
      :placeholder="placeholderText"
      :separators="[';', ' ', '\n', ',']"
      @before-deleting-tag="beforeDelete"
      @before-adding-tag="beforeAdd"
      @tags-changed="tagsChanged"
      :add-only-from-autocomplete="false"
      :validation="validation"
      class="overflow-visible w-full zfix"
      >
        <template v-slot:tag-center="slot">
          <div v-tooltip="{
            content: getTitleById(slot.tag.text),
            classes: 'tooltipPop'
          }">{{slot.tag.text}}</div>
        </template>
      </vue-tags-input>
      <span
      class="text-danger text-sm"
      :hidden="!(tags.length > 0 && !tagsValid)"
      v-show="(tags.length > 0 && !tagsValid)"
      >
      </span>
    </div>
    <div v-if="sendButton" class="w-full sm:w-full md:w-2/12 flex justify-end sm:justify-end md:justify-start ml-0 md:ml-4 mt-2 md:mt-0">
      <vs-button
        type="filled"
        @click="handleSend"
        :disabled="tags.length === 0 || !tagsValid"
        >{{sendText}}</vs-button
        >
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default{
  props: {
    placeholderText: {
      type: String,
      default: function () {
        return this.$t('selecionar-questao-via-codigo')
      }
    },
    sendText: {
      type: String,
      default: function () {
        return this.$t('adicionar-questoes')
      }
    },
    validationText: {
      type: String,
      default: function () {
        return this.$t('adicione-somente-codigos-numericos-validos')
      }
    },
    sendButton: {
      type: Boolean,
      default: false
    },
    sendButtonHandle: {
      type: Function,
      default: null
    },
    autocompleteItems: {
      type: Array,
      default: () => { return [] }
    },
    validation: {
      type: Array,
      default() {
        return [
          {
            classes: 'valid-id',
            rule: ({ text }) => {
              return this.$utils.validation.number(text)
            }
          }
        ]
      }
    }
  },

  components: {
    VueTagsInput
  },

  watch: {
    tag(value) {
      this.$emit('tagInput', value)
    },
    selectedQuestions() {
      this.tagsChanged()
    }
  },

  data () {
    return {
      tag: '',
      tagsValid: false,
      tags: []
    }
  },

  computed: {
    selectedQuestions() {
      return this.$store.getters['basequestion/selectedQuestions']
    }
  },

  methods: {
    beforeAdd(event) {
      if ('suggested' in event.tag) {
        this.$store.dispatch('basequestion/selectQuestion', event.tag.data).then(
          (success) => {
            this.tag = ''
          },
          (error) => {
            this.dlog('Could not add question to store, id:', event.tag.data.id, ', error: ', error)
          }
        )
      }
    },
    beforeDelete(event) {
      if (event.tag) {
        this.$store.dispatch('basequestion/deselectQuestion', event.tag.data.id).then(
          success => {
            this.dlog('removed question, id:', event.tag.data.id)
          },
          error => {
            this.dlog(error)
          })
      }
    },
    tagsChanged() {
      this.tags = this.$store.getters['basequestion/selectedIDs']
    },
    dlog(...things) {
      this.devLog('BaseQuestion-SelectedQuestions', ...things)
    },
    handleSend() {
      if (this.sendButtonHandle === null) {
        this.$emit('tagsSent', this.tags)
      } else {
        this.sendButtonHandle(this.tags)
      }
    },
    getTitleById(id) {
      const item = this.autocompleteItems.filter(element => {
        return +element.id === +id
      })
      if (item) {
        return item.title
      }
      return ''
    }
  }

}
</script>

<style lang="scss" scoped>
  .vue-tags-input {
    max-width: 100% !important;    
  }
  .zfix {
      z-index: 900;
  }
  #idtags > div {
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: .5rem !important;
    border-color: #b8c2cc80 !important;
  }
</style>
