<template>
  <div>
    <label v-show="!isEmpty(difficulty)" :class="style">
      {{ $t(`random_questions.group_key.${difficulty}`) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    difficulty: {
      type: String,
      default: ''
    }
  },
  computed: {
    style() {
      switch (this.difficulty) {
      case 'E':
        return 'bg-success border border-solid rounded border-success text-white'
      case 'M':
        return 'bg-warning border border-solid rounded border-warning text-white'
      case 'D':
        return 'bg-danger border border-solid rounded border-danger text-white'
      default:
        return ''  
      }
    }
  }

}
</script>

<style>

</style>