<template>
  <div class="BaseQuestionGrid">
    <InfiniteGrid ref="grid" :service="service" class="infiniteGrid">
      <template v-slot:item="data">
        <base-question-card
          @action="$emit('questioncard-action', $event)"
          :mode="mode" :question="data.item" :key="data.item"
        />
      </template>
    </InfiniteGrid>
  </div>
</template>

<script>
import InfiniteGrid from '@/components/infiniteGrid.vue'
import BaseQuestionCard from './BaseQuestionCard.vue'

export default {
  props: {
    service: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'select'
    }
  },
  components: {
    BaseQuestionCard,
    InfiniteGrid
  },
  data: () => ({
    filteringSelectedQuestions: false
  }),
  methods: {
    dlog(...things) {
      this.devLog('BaseQuestionGrid.vue', ...things)
    },
    applyGridFilters(filters, scrollTop) {

      if (scrollTop) {
        window.scrollTo(0, 0)
        const popupContent = this.$el.querySelector('vs-popup--content')
        if (popupContent) {
          popupContent.scrollTo(0, 0)
        }
      }
      
      const customFilters = []
      const items = []
      const filterSelectedQuestions = filters.find((filterDefinition) => filterDefinition.filter === 'filterSelectedQuestions')
      this.dlog('Filters received: ', filters)
      
      if (filterSelectedQuestions && filterSelectedQuestions.val) {

        this.filteringSelectedQuestions = true
        this.$refs.grid.applySimpleContent(this.$store.getters['basequestion/selectedQuestions'])

      } else if (filters.length !== 0) {

        this.filteringSelectedQuestions = false
        filters.forEach((filterObj, key) => {
          if (filterObj.filter !== 'search') {
            customFilters[filterObj.filter] = filterObj.val
            customFilters.length++
          }
        })

        const hasSearch = filters.find((filterObj) => {
          return filterObj.filter === 'search'
        })

        if (hasSearch !== undefined) {
          items.push(hasSearch.val)
        }

        this.$refs.grid.flashFilters(items, customFilters)
      }
    }
  }
}
</script>

<style lang="scss">

.basequestionGridContainer .vs-table--tbody {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

</style>

<style lang="scss" scoped>
.infiniteGrid {
  & /deep/ .vs-table-primary .is-selected {
    color: $content-color !important;
    -webkit-box-shadow: 0 !important;
    box-shadow: 0 !important;
    background: transparent !important;
  }

  & /deep/ .vs-table--tr {
    transform: none !important;
    .vs-table--td {
      padding: 0px 0 10px 10px !important;
    }
  }

  & /deep/ .vs-table--tbody-table {
    min-width: 200px !important;
  }

  & /deep/ .infinite-grid-item .vx-card {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  & /deep/ .vs-con-table .vs-con-tbody {
    background: none !important;
    border: none !important;
  }

}
</style>
