<template>
  <div class="h-full BaseQuestionModal">
    <div class="grid grid-cols-12">
      <div class="col-span-12 mb-2" v-if="filtersFetched || isMobile">
        <div class="grid grid-rows-1">
          <div class="grid grid-cols-12 items-center my-2" v-if="selectMode">
            <div class="col-span-12 md:col-span-8 lg:col-span-9">
              <BaseQuestionSelectedQuestions
              @tagInput="suggestQuestionById"
              :autocompleteItems="computedAutocompleteQuestions"
              />
            </div>

            <div class="col-span-12 md:col-span-4 lg:col-span-3 md:mt-0 mt-2 flex gap-2 flex-col sm:flex-row-reverse">
              <vs-button @click="handleAddQuestionsButton" class="flex-grow-0 flex-shrink-0" icon="add" :disabled="!showAddSelectedButton">
                {{$t('base_question.modal.add_selected')}}
              </vs-button>
              <vs-button
                class="flex-grow md:flex-grow-0 flex-shrink-0"
                v-if="isMobile"
                icon-pack="feather"
                icon="icon-filter"
                @click="showFilters = !showFilters"
              >
                {{showFilters ? $t('esconder-filtros') : $t('mostrar-filtros')}}
              </vs-button>
            </div>

          </div>
          <div v-if="manageMode" class="flex gap-2 col-span-12 justify-end my-2">
            <vs-button
              v-if="isMobile"
              icon-pack="feather"
              icon="icon-filter"
              @click="showFilters = !showFilters"
            >
              {{showFilters ? $t('esconder-filtros') : $t('mostrar-filtros')}}
            </vs-button>
            <vs-dropdown class="flex-grow-0">
              <vs-button icon="add">
              {{$t('base_question.modal.create_new')}}
              </vs-button>
              <vs-dropdown-menu class="newQuestionDropdown" style="width: 200px;">
                <vs-dropdown-item>
                  <vs-button
                    class="p-2 w-full text-left"
                    @click="handleNewQuestion('simple_text')"
                    type="flat" color="primary" icon="edit"
                  >
                    {{$t('question_type.simple_text')}}
                  </vs-button>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <vs-button
                    class="p-2 w-full"
                    @click="handleNewQuestion('multiple_choice')"
                    type="flat" color="primary" icon="list"
                  >
                    {{$t('question_type.multiple_choice')}}
                  </vs-button>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <vs-button
                    class="p-2 w-full"
                    @click="handleNewQuestion('check_box')"
                    type="flat" color="primary" icon="check_box">
                    {{$t('question_type.check_box')}}
                  </vs-button>
                </vs-dropdown-item>
                <vs-dropdown-group :vs-label="$t('importacao')" v-permission="'base_question.import'">
                  <vs-dropdown-item>
                    <vs-button
                      class="p-2 w-full"
                      @click="showBaseQuestionImportPopup = true"
                      type="flat"
                      color="primary"
                      icon-pack="feather"
                      icon="icon-file-plus">
                      {{$t('action.import')}}
                   </vs-button>
                  </vs-dropdown-item>
                </vs-dropdown-group>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-3 h-full">
        <BaseQuestionFilters
          v-if="!isMobile || showFilters"
          @filtersUpdated="filterGrid"
          @showModalItems="filtersFetched = true"
        />
      </div>
      <div class="col-span-12 md:col-span-9" v-show="filtersFetched || isMobile">
        <BaseQuestionGrid
          @questioncard-action="$emit('action', $event)"
          ref="baseQuestionGrid"
          :mode="mode"
          :service="service"
          class="md:mt-0 p-0 mt-2 md:pl-2 removeheader basequestiongrid"/>
      </div>
    </div>
    <vs-popup
      :title="$t('importacao')"
      :active.sync="showBaseQuestionImportPopup"
      style="overflow-y: auto; z-index: 54000;"
      >
      <div class="grid grid-cols-1 gap-4 pt-4">
        <content-file-upload
          @input="importBaseQuestionData"
          :uploadUrl="sheetUploadImportQuestionUrl"
          :autoProcessUpload="false"
          :maxFiles="1"
          customMessageSuccess="iniciando-processamento-do-arquivo"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>

import BaseQuestionSelectedQuestions from './BaseQuestionSelectedQuestions'
import BaseQuestionGrid from './BaseQuestionGrid'
import BaseQuestionFilters from './BaseQuestionFilters'
import BaseQuestionService from '@/services/api/BaseQuestionService'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ContentFileUpload from '@/components/ContentFileUpload.vue'

export default {
  components: {
    BaseQuestionSelectedQuestions,
    BaseQuestionGrid,
    BaseQuestionFilters,
    VuePerfectScrollbar,
    ContentFileUpload
  },
  data: () => {
    return {
      service: null,
      autocompleteQuestions: [],
      showFilters: false,
      isMobile: false,
      filtersFetched: false,
      showBaseQuestionImportPopup: false,
    }
  },
  props: {
    mode: {
      default: 'select',
      type: String
    }
  },
  created() {
    this.service = BaseQuestionService.build(this.$vs)
  },
  watch: {
    windowWidth(val) {
      this.updateOnBreakpoint()
    }
  },
  mounted() {
    this.updateOnBreakpoint()
  },
  computed: {
    selectMode() {
      return this.mode === 'select'
    },
    manageMode() {
      return this.mode === 'manage'
    },
    mobile() {
      return this.isMobile
    },
    smallWidth() {
      return this.getCurrentBreakpoint()
    },
    computedAutocompleteQuestions() {
      return this.autocompleteQuestions
    },
    windowWidth() {
      return this.$store.getters.windowWidth
    },
    showFilterButtonStyle() {
      return `w-full ${this.showFilters ? 'rounded-t-lg' : 'rounded-lg'}  removeButtonDefaultRound`
    },
    baseQuestionFiltersStyle() {
      return `filters col-span-1 md:col-span-3 p-3 border-faint-grey ${(this.showFilters && this.mobile) ? 'rounded-b-lg' : 'rounded-lg'} border border-solid overflow-auto`
    },
    selectedQuestions() {
      return this.$store.getters['basequestion/selectedQuestions']
    },
    showAddSelectedButton() {
      return this.selectedQuestions.length > 0
    },
    sheetUploadImportQuestionUrl(){
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/base_questions/import/sheets`
    }
  },
  methods: {
    dlog(...things) {
      this.devLog('basequestion/modal', ...things)
    },
    updateOnBreakpoint() {
      const breakpoint = this.getCurrentBreakpoint()
      this.isMobile = breakpoint === null || breakpoint === 'sm'
    },
    handleAddQuestionsButton() {
      this.$emit('pressedAddQuestions')
    },
    handleNewQuestion(type) {
      this.$emit('new-question-pressed', type)
    },
    filterGrid(filters, scrollToTop) {
      this.$refs.baseQuestionGrid.applyGridFilters(filters, scrollToTop)
    },
    suggestQuestionById(id) {
      if (id.length === 0 || !this.$utils.validation.number(id)) {
        this.autocompleteQuestions = []
        return
      }

      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.service.getSuggestionOnId(id).then(response => {
          this.autocompleteQuestions = response.map(question => {
            const data = JSON.parse(question.data)
            const title = `${question.id} - ${this.shortTitle(data.data.title, 60)}`
            const suggestedQuestion = {
              text: title,
              data: question,
              suggested: true
            }
            this.dlog('SUGGESTION| Mapped question, id:', suggestedQuestion)
            return suggestedQuestion
          })
        })
      }, 500)
    },

  },
  importBaseQuestionData(data){
    this.filterGrid([], true)
  }
}
</script>

<style lang="scss">
.removeheader .vs-table--header div {
  padding-bottom: 0px !important;
}

.newQuestionDropdown .vs-button.includeIcon {
  justify-content: flex-start;
}

.BaseQuestionModal  .filters {
  // height: calc(100vh * 0.65557291) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.BaseQuestionModal .removeButtonDefaultRound {
  border-radius: 0px;
}

.BaseQuestionModal .vs-popup--content {
  height: 100% !important;
}


.BaseQuestionModal .vs-table--td {
  padding: unset !important;
}

</style>

<style lang="scss" scoped>
  .basequestiongrid /deep/ .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: unset !important;
    padding-bottom: 0.5rem !important;
  }

  @screen md {
    .basequestiongrid /deep/ .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
      padding: 0 0 10px 10px !important;
    }
  }
</style>
