import Rest from '../Rest'

export default class BaseQuestionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/base_questions'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getSuggestionOnId(id: any) {
    return this.get(`/suggest_id/${id}`).then( data => {
      return data
    })
  }

  getThemeSuggestion(name: string) {
    return this.get(`/suggest_theme/${name}`).then( data => {
      return data
    })
  }

  getSubThemeSuggestion(name: string) {
    return this.get(`/suggest_subtheme/${name}`).then( data => {
      return data
    })
  }

  getFilters() {
    return this.get('/filters').then( data => {
      return data
    })
  }
}
