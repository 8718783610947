<template>
  <vx-card
   :title="`${question.id} ${question.origin ? `- ${question.origin}` : ''}`"
   :class="cardClasses"
  >
  <div class="flex flex-col">
    <div style="position: absolute; right: 25px;" v-if="question.difficulty_level">
      <difficulty-indicator :difficulty="_.get(question, 'difficulty_level', '')"/>
    </div>
    <div class="mb-4 w-full">
      <div class="flex flex-wrap gap-1">
        <div
          :key="i"
          v-for="(tag, i) in question.tags"
          class="flex-shrink-0 border-faint-grey border-solid border py-0 px-1  rounded text-xs"
        >{{responsiveTruncate(tag)}}</div>
      </div>
    </div>

    <div class="w-full" v-if="questionData" :style="contentStyle">
      <component class="questionDataContainer" :item="questionData" :showAnswerSection="false" :is="$utils.format.component.fromQuestionType(questionData.type).show"></component>
      <div :hidden="!collapseContent" :style="faderElementStyle"/>
    </div>

    <div @click.stop="expandCard" class="hover:text-primary cursor-pointer mt-2 w-full" >
      <div class="flex justify-center">
        <feather-icon
          class="mr-1"
          svgClasses="w-7 h-7"
          :icon="collapseContent? 'ChevronDownIcon' : 'ChevronUpIcon'"
        />
      </div>
    </div>
    <div :class="actionButtonsContainerClass">
      <vs-checkbox
        v-if="selectMode"
        @input="selectQuestion"
        v-model="selected"
      >
      <span>{{$t('base_question.card.action.select')}}</span>
      </vs-checkbox>
      <vs-button
        v-if="canCustomize"
        type="flat"
        icon-pack="feather"
        icon="icon-copy"
        v-tooltip="{
          content: $t('base_question.card.action.customize'),
          delay: { show: 500, hide: 100 }
        }"
        @click="customizeQuestion"
        :class="actionButtonClass"
      >{{textForAction('action.customize')}}</vs-button>
      <vs-button
        v-if="canAlter"
        type="flat"
        icon-pack="feather"
        icon="icon-edit"
        v-tooltip="{
          content: $t('base_question.card.action.edit'),
          delay: { show: 500, hide: 100 }
        }"
        @click="editQuestion"
        svgClasse
        :class="actionButtonClass"
      >{{textForAction('action.edit')}}</vs-button>
      <vs-button
        v-if="canAlter"
        color="danger"
        type="flat"
        icon-pack="feather"
        icon="icon-trash"
        v-tooltip="{
          content: $t('base_question.card.action.delete'),
          delay: { show: 500, hide: 100 }
        }"
        @click="destroyQuestion"
        :class="actionButtonClass"
      >{{textForAction('action.delete')}}</vs-button>
    </div>
  </div>

  </vx-card>
</template>

<script>

import MultipleChoiceShow from '@/views/apps/questionnaires/answers/questions/show/MultipleChoiceShow'
import CheckBoxShow from '@/views/apps/questionnaires/answers/questions/show/CheckBoxShow'
import SimpleTextShow from '@/views/apps/questionnaires/answers/questions/show/SimpleTextShow'
import DifficultyIndicator from '@/components/DifficultyIndicator.vue'

export default {
  components: {
    MultipleChoiceShow,
    CheckBoxShow,
    SimpleTextShow,
    DifficultyIndicator
  },
  props: {
    question: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'select'
    }
  },
  data: () => ({
    tags: [],
    collapseContent: true,
    faderWidth: 0,
    checkboxSelected: false,
    selected: false,
  }),
  watch: {
    windowWidth(width) {
      this.updateFaderWidth()
    },
    selected(value) {
      this.checkboxSelected = value
    },
    selectedQuestions(selectedQuestions) {
      if (!this.isEmpty(selectedQuestions)) {
        const questions = selectedQuestions.filter(question => {
          return question.id === this.question.id
        })
        if (questions.length === 0) {
          this.selected = false
        }
      } else {
        this.selected = false
      }
    }
  },
  computed: {
    selectMode() {
      return this.mode === 'select'
    },
    manageMode() {
      return this.mode === 'manage'
    },
    breakpoint() {
      return this.$store.getters['windowBreakPoint']
    },
    canCustomize() {
      return this.manageMode && this.question.owner_id === null
    },
    canAlter() {
      return this.manageMode &&
          (
            this.$acl.hasPermission('base_question.system.manage') ||
            this.question.owner_id === this.currentUserId
          )
    },
    actionButtonTinyMode() {
      return this.windowWidth < 400 && this.canAlter && this.canCustomize
    },
    actionButtonClass() {
      const base = 'flex-grow-0 flex-shrink'
      if (this.actionButtonTinyMode) return `${base} bigger-icon`
      return base
    },
    actionButtonsContainerClass() {
      const base = 'w-full flex gap-2 colorize'
      const justification = this.actionButtonTinyMode ? 'justify-between' : 'justify-start'
      return `${base} ${justification}`
    },
    selectedQuestions() {
      return this.$store.getters['basequestion/selectedQuestions']
    },
    questionData() {
      if (this.question) {
        return JSON.parse(this.question.data)
      }
      return undefined
    },
    contentStyle() {
      const collapsed = this.collapseContent
      const baseStyle = 'overflow-y: hidden; overflow-x: hidden;'
      return baseStyle + (collapsed ?
        'height: 100px;' : 'height: unset;')
    },
    faderElementStyle() {
      return `
        position: absolute;
        height: 80px;
        width: ${this.faderWidth}px;
        bottom: 70px;
        background: linear-gradient(to top, rgba(255, 255, 255, 0.7) 0%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0) 100%);
        `
    },
    windowWidth() {
      return this.$store.getters.windowWidth
    },
    cardClasses() {
      const base = 'pt-0 m-0 w-full hover:text-black BaseQuestionCard'
      return this.selected ? `${base} border-solid border-primary border-l-8 border-r-0 border-t-0 border-b-0` : base
    },
    currentUserId() {
      return this.$store.state.AppActiveUser.id
    },
    difficulytLevelText() {
      switch (this.question.difficulty_level) {
      case 'E':
        return this.$t('facil')
      case 'M':
        return this.$t('media')
      case 'D':
        return this.$t('dificil')
      default:
        return ''
      }
    }
  },
  methods: {
    dlog(...things) {
      this.devLog(this.$t('question-card-id-this-question-id', [this.question.id]), ...things)
    },
    textForAction(action) {
      if (this.actionButtonTinyMode) return ''
      return this.$t(action)
    },
    questionDataContainerWidth() {
      return this.$utils.DOM.measure.elementWidth('.questionDataContainer')
    },
    expandCard() {
      this.collapseContent = !this.collapseContent
    },
    updateFaderWidth() {
      this.faderWidth = this.questionDataContainerWidth()
    },
    selectQuestion() {
      if (this.selected) {
        this.$store.dispatch('basequestion/selectQuestion', this.question).then((result) => {
          this.dlog('CHECKBOX selected question, id:', this.question.id, '\n', result || '')
        })
      } else {
        this.$store.dispatch('basequestion/deselectQuestion', this.question.id).then((result) => {
          this.dlog('CHECKBOX deselected question, id:', this.question.id)
        })
      }
    },
    customizeQuestion() {
      const questionData = JSON.parse(this.question.data)
      questionData.id = null
      const alteration = {
        type: 'customize',
        data: {
          ...this.question,
          data: JSON.stringify(questionData),
          id: null,
          owner_id: this.currentUserId,
          created_at: null,
          searchable: null,
          parent_id: this.question.id
        }
      }
      this.$emit('action', alteration)
    },
    editQuestion() {
      this.$emit('action', {type: 'edit', data: {...this.question}})
    },
    destroyQuestion() {
      this.$emit('action', {type: 'destroy', data: {id: this.question.id, origin: this.question.origin}})
    },
    responsiveTruncate(tag) {
      const currentBreakpoint = this.getCurrentBreakpoint()
      let amount = 10
      switch (currentBreakpoint) {
      case 'sm':
        amount = 20
        break
      case 'md':
        amount = 30
        break
      case 'lg':
      case 'xl':
        amount = 35
      }
      return this.truncate(tag, amount)
    },
    checkSelected() {
      const selectedQuestions = this.selectedQuestions
      selectedQuestions.forEach(question => {
        if (question.id === this.question.id) {
          this.selected = true
        }
      })
    }
  },
  mounted() {
    this.checkSelected()
    this.updateFaderWidth()
  }
}
</script>

<style lang="scss">
.pt-0 .vx-card__body {
  padding-top: 3px !important;
}

.BaseQuestionCard img {
  max-width: 100% !important;
}

.BaseQuestionCard .bigger-icon i {
  scale: 1.75 !important;
}

</style>
