<template>
  <div class="flex flex-col justify-between" v-if="filtersFetched">
    <vs-input 
      icon="search" 
      v-model="search"
      @keyup="searchChanged"
      @keyup.enter="enterPressed"
      class="justify-self-end searchbar w-full mb-4">
    </vs-input>
    <fieldset>
      <legend style="padding: 0 5px 0 5px;">{{ $t('questoes') }}</legend>
      <div class="grid grid-cols-2 mb-1">
        <vs-radio v-model="source" vs-name="sourcefilter" vs-value="personal" @input="selectedOption">
          {{ $t('minhas') }}
        </vs-radio>
        <vs-radio v-model="source" vs-name="sourcefilter" vs-value="system" @input="selectedOption">
          {{ $t('compartilhadas') }}
        </vs-radio>
      </div>
      <div v-if="selectedQuestionAmount !== 0" class="w-full mb-2 mt-3 checkboxNoMargin">
        <vs-checkbox v-model="filterSelectedQuestions">{{ $t('somente-selecionadas') }}</vs-checkbox>
      </div>
    </fieldset>
    <vs-select
      class="w-full mb-2 mt-2"
      :label="$t(selects[selectName].label())"
      v-model="selects[selectName].data.selected"
      :key="selectName"
      autocomplete
      v-for="(selectName) in visibleSelects"
      :disabled="'disabled' in selects[selectName]? selects[selectName].disabled() : false"
      @input="selectedOption"
      >
      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in selects[selectName].data.options" />
    </vs-select>
    <div class="hover:text-primary cursor-pointer w-full my-2" @click="showMore = !showMore">
      <div class="flex justify-center">
        <feather-icon
          class="mr-1"
          svgClasses="w-5 h-5"
          :icon="showMore? 'MinusIcon' : 'PlusIcon'"
        />
        <span class="text-sm">
          {{!showMore ? 'Mais Filtros' : 'Menos Filtros'}}
        </span>
      </div>
    </div>

    <div class="gap-1 pb-2 justify-center flex">
      <vs-button
        class="md:text-xs lg:text-sm"
        :disabled="autoUpdate" 
        @click="emitUpdate" 
        icon-pack="feather"
        icon="icon-filter"
      >{{ $t('filtrar') }}
      </vs-button>

      <vs-button 
        v-show="showClearButton"
        class="md:text-xs lg:text-sm"
        @click="clearFilters"
        color="danger" 
        icon="clear"
        type="border"
      >{{ $t('action.clear') }}
      </vs-button>

      <vs-checkbox v-model="autoUpdate"
        class="modifycheckbox z-50 m-0 text-xs sm:text-base md:text-xs overflow-ellipsis"
      >{{checkboxAutoText}}</vs-checkbox>
    </div>

  </div>
</template>

<script>
import BaseQuestionService from '@/services/api/BaseQuestionService'
import FeatherIcon from '@/components/FeatherIcon.vue'
import { normalizeString } from '@/util/String'

//These two functions are defined here to reduce repetition in data.selects
const onShowMore = function(data) {
  return data.showMore
}

const emptySelect = function() {
  return {selected:'none', options:[]}
}

export default {
  components: {
    FeatherIcon
    
  },
  data: () => ({
    source: 'personal',
    selects: {
      education_level: {
        data: emptySelect(), label: function () { return 'validation.attributes.education_level' }, disabled: () => false
      },
      course: {
        data: emptySelect(), label: function () { return 'validation.attributes.course' }, disabled: () => false
      },
      discipline: {
        data: emptySelect(), label: function () { return 'discipline' }, disabled: () => false
      },
      theme: {
        data: emptySelect(), label: function () { return 'tema' }, show: onShowMore
      },
      subtheme: {
        data: emptySelect(), label: function () { return 'sub-tema' }, show: onShowMore
      },
      specific_theme: {
        data: emptySelect(), label: function () { return 'tema-especifico' }, show: onShowMore
      },
      difficulty: {
        data: {
          selected: 'none',
          options: []
        },
        label: function () { return 'validation.attributes.difficulty' },
        show: onShowMore
      },
      question_type: {data: emptySelect(), label: function () { return 'tipo-de-questao' }, show: onShowMore},
    },
    filterSelectedQuestions: false,
    search: '',
    autoUpdate: false,
    showMore: false,
    service: null,
    filtersFetched: false,
    showClearButton: false,
    breakpoint: null,
    allDisciplines: []
  }),
  computed: {
    visibleSelects() {
      return Object.keys(this.selects).filter((select, index) => {
        return 'show' in this.selects[select] ? this.selects[select].show(this.$data) : true
      })
    },
    selectedQuestionAmount() {
      const questions = this.$store.getters['basequestion/selectedQuestions']
      return questions && questions.length
    },
    windowWidth() {
      return this.$store.getters.windowWidth
    },
    checkboxAutoText() {
      return this.breakpoint === 'md' ? 'Auto...' : this.$t('automatico')
    }
  },
  watch: {
    windowWidth() {
      this.breakpoint = this.getCurrentBreakpoint()
    },
    selectedQuestionAmount(val) {
      if (val === 0 && this.filterSelectedQuestions === true) {
        this.filterSelectedQuestions = false
        this.emitUpdate()
      }
    },
    source(val) {
      this.applyDisciplineFilter()
    }

  },
  methods: {
    selectedOption(option) {
      this.showClearButton = true
      if (this.autoUpdate) {
        if (this.updateTimeout) clearTimeout(this.updateTimeout)
        this.updateTimeout = setTimeout(() => {
          this.emitUpdate()
        }, 500)
      }
    },
    emitUpdate() {
      this.$emit('filtersUpdated', this.currentFilters(), true)
      this.scrollToTop()
    },
    fillSelectOptions(which, options) {
      if (!options.find((option) => {
        return option.value === 'none'
      })) {

        switch (which) {
        case 'difficulty':
          break
        case 'education_level':
          break      
        default:
          options = options.sort((first, second) => {
            const firstText = normalizeString(first.text)
            const secondText = normalizeString(second.text)
            return firstText > secondText
          })
          break
        }

        /**
         * We need a switch due to portuguese grammatics
         * requiring the correctly worded gender of the word "none"
         * 
         * discipline in portuguese is a female gendered word, so we use female 'none' "Nenhuma"
         * theme in portuguese is a male gendered word, so we use male 'none' "Nenhum"
         */
        let term = ''
        switch (which) {
        case 'difficulty':
        case 'discipline':
          term = this.$t('term.none_f')
          break      
        default:
          term = this.$t('term.none')
          break
        }

        options.unshift({
          text: term,
          value: 'none'
        })
      }

      if (which !== 'discipline') {
        this.selects[which].data.options = {... options}
      } else {
        this.allDisciplines = [...options]
        this.applyDisciplineFilter()
      }
      // this.$set(this.$data, `selects.${which}.data.options`, options)
    },
    /**
     * @param {Array} which filter values to select, when not provided, return all.
     * @returns {Object} selected filter values along with source and search filters value always. 
     */
    currentFilters(which = []) {
      if (which.length === 0) {
        which = Object.keys(this.selects) 
      }

      const filterParams = []

      which.forEach((selectName, index) => {
        const obj = {}
        obj['filter'] = selectName
        obj['val'] = this.selects[selectName].data.selected
        if (obj['val'] !== 'none' && obj['val'] !== null) {
          filterParams.push(obj)
        }
      })
  
      filterParams.push({ filter: 'source', val: this.source })
      filterParams.push({ filter: 'filterSelectedQuestions', val: this.filterSelectedQuestions})
      if (!this.isEmpty(this.search) && this.search.length !== 0) {
        filterParams.push({ filter: 'search', val: this.search})
      }

      return filterParams
    },
    enterPressed() {
      clearTimeout(this.searchTimeout)
      this.emitUpdate()
      this.showClearButton = true
    },
    searchChanged() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.emitUpdate()
        this.showClearButton = true
      }, 1000)
    },
    clearFilters() {
      Object.keys(this.selects).forEach(key => {
        this.selects[key].data.selected = 'none'
      })
      this.source = 'personal'
      this.search = ''
      this.showClearButton = false

      if (this.autoUpdate) {
        this.emitUpdate()
      }

      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
      const popupContent = this.$el.offsetParent.querySelector('.vs-popup--content')
      if (popupContent) {
        popupContent.scrollTo(0, 0)
      }
    },
    dlog(...things) {
      this.devLog('BaseQuestion-Filters', ...things)
    },
    applyDisciplineFilter() {
      this.selects['discipline'].data.options = this.allDisciplines.filter((discipline) => {
        return (discipline.system === (this.source === 'system')) || discipline.value === 'none'
      })
    }
  },
  mounted() {
    this.service = BaseQuestionService.build(this.$vs)
    this.$vs.loading()
    this.service.getFilters().then(
      (data) => {
        Object.keys(data).forEach((filter, index) => {
          this.dlog('Filling: ', filter, ' with: ', data[filter])
          this.fillSelectOptions(filter, data[filter])
          this.$nextTick(() => {
            // this.setupDisciplinesBuffer()
          })
        })
        this.filtersFetched = true
        this.$emit('showModalItems')
        this.$vs.loading.close()
      },
      (error) => {
        this.filtersFetched = true
        this.$emit('showModalItems')
        this.$vs.loading.close()
        this.dlog(error)
      }
    )
  },
}
</script>

<style lang="scss">
  .modifycheckbox .checkbox_x {
    width: 15px;
    height: 15px;
  }
  .checkboxNoMargin > div {
    margin: 0px !important;
  }
  fieldset {
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
  }
</style>
