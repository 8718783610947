var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full BaseQuestionModal" },
    [
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _vm.filtersFetched || _vm.isMobile
          ? _c("div", { staticClass: "col-span-12 mb-2" }, [
              _c("div", { staticClass: "grid grid-rows-1" }, [
                _vm.selectMode
                  ? _c(
                      "div",
                      { staticClass: "grid grid-cols-12 items-center my-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-span-12 md:col-span-8 lg:col-span-9",
                          },
                          [
                            _c("BaseQuestionSelectedQuestions", {
                              attrs: {
                                autocompleteItems:
                                  _vm.computedAutocompleteQuestions,
                              },
                              on: { tagInput: _vm.suggestQuestionById },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-span-12 md:col-span-4 lg:col-span-3 md:mt-0 mt-2 flex gap-2 flex-col sm:flex-row-reverse",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "flex-grow-0 flex-shrink-0",
                                attrs: {
                                  icon: "add",
                                  disabled: !_vm.showAddSelectedButton,
                                },
                                on: { click: _vm.handleAddQuestionsButton },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("base_question.modal.add_selected")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm.isMobile
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass:
                                      "flex-grow md:flex-grow-0 flex-shrink-0",
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-filter",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showFilters = !_vm.showFilters
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showFilters
                                            ? _vm.$t("esconder-filtros")
                                            : _vm.$t("mostrar-filtros")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.manageMode
                  ? _c(
                      "div",
                      {
                        staticClass: "flex gap-2 col-span-12 justify-end my-2",
                      },
                      [
                        _vm.isMobile
                          ? _c(
                              "vs-button",
                              {
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-filter",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showFilters = !_vm.showFilters
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showFilters
                                        ? _vm.$t("esconder-filtros")
                                        : _vm.$t("mostrar-filtros")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "vs-dropdown",
                          { staticClass: "flex-grow-0" },
                          [
                            _c("vs-button", { attrs: { icon: "add" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("base_question.modal.create_new")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "vs-dropdown-menu",
                              {
                                staticClass: "newQuestionDropdown",
                                staticStyle: { width: "200px" },
                              },
                              [
                                _c(
                                  "vs-dropdown-item",
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "p-2 w-full text-left",
                                        attrs: {
                                          type: "flat",
                                          color: "primary",
                                          icon: "edit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleNewQuestion(
                                              "simple_text"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "question_type.simple_text"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "p-2 w-full",
                                        attrs: {
                                          type: "flat",
                                          color: "primary",
                                          icon: "list",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleNewQuestion(
                                              "multiple_choice"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "question_type.multiple_choice"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "p-2 w-full",
                                        attrs: {
                                          type: "flat",
                                          color: "primary",
                                          icon: "check_box",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleNewQuestion(
                                              "check_box"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("question_type.check_box")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-group",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "base_question.import",
                                        expression: "'base_question.import'",
                                      },
                                    ],
                                    attrs: { "vs-label": _vm.$t("importacao") },
                                  },
                                  [
                                    _c(
                                      "vs-dropdown-item",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "p-2 w-full",
                                            attrs: {
                                              type: "flat",
                                              color: "primary",
                                              "icon-pack": "feather",
                                              icon: "icon-file-plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showBaseQuestionImportPopup = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("action.import")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-span-12 md:col-span-3 h-full" },
          [
            !_vm.isMobile || _vm.showFilters
              ? _c("BaseQuestionFilters", {
                  on: {
                    filtersUpdated: _vm.filterGrid,
                    showModalItems: function ($event) {
                      _vm.filtersFetched = true
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.filtersFetched || _vm.isMobile,
                expression: "filtersFetched || isMobile",
              },
            ],
            staticClass: "col-span-12 md:col-span-9",
          },
          [
            _c("BaseQuestionGrid", {
              ref: "baseQuestionGrid",
              staticClass:
                "md:mt-0 p-0 mt-2 md:pl-2 removeheader basequestiongrid",
              attrs: { mode: _vm.mode, service: _vm.service },
              on: {
                "questioncard-action": function ($event) {
                  return _vm.$emit("action", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: _vm.$t("importacao"),
            active: _vm.showBaseQuestionImportPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showBaseQuestionImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 pt-4" },
            [
              _c("content-file-upload", {
                attrs: {
                  uploadUrl: _vm.sheetUploadImportQuestionUrl,
                  autoProcessUpload: false,
                  maxFiles: 1,
                  customMessageSuccess: "iniciando-processamento-do-arquivo",
                  accept:
                    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
                on: { input: _vm.importBaseQuestionData },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }