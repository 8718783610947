var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEmpty(_vm.difficulty),
            expression: "!isEmpty(difficulty)",
          },
        ],
        class: _vm.style,
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.$t("random_questions.group_key." + _vm.difficulty)) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }